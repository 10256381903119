import React, { useState, useContext } from 'react';
import { Table, Select, Tag, message, Tooltip, Input } from 'antd';
import { MyContext } from '../MyProvider';
import axios from 'axios';


const { Option } = Select;
const { Search } = Input;

const Submissions = () => {
  const { service_booking, service_bookingApi } = useContext(MyContext);
  const [selectedColumns, setSelectedColumns] = useState(['name', 'email', 'mobile_id', 'event_date', 'service_status']);
  const [filter, setFilter] = useState('name');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const handleColumnChange = (value) => {
    setSelectedColumns(value);
  };

  const handleStatusChange = (record) => {
    const newStatus = record.service_status === 'pending' ? 'completed' : 'pending';
    axios.put(`${process.env.REACT_APP_API_BASE_URL}/service_booking.php`, { ...record, service_status: newStatus }, {
      headers: {
        "Accept": "*/*",
        "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
      }
    }).then((res) => {
      message.success('Service Booking updated successfully!');
      service_bookingApi();
    }).catch((err) => {
      message.error('Service Booking updated Error!');
    });
  };

  const handleFilterChange = (value) => {
    setFilter(value);
    setSearchTerm('');
    setCurrentPage(1);
     };

  const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      setCurrentPage(1); // Reset to first page on search change  
    
  };

  const allColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_id',
      key: 'mobile_id',
    },
    {
      title: 'Customer Id',
      dataIndex: 'customer_no',
      key: 'customer_no',
    },
    {
      title: 'Event Date',
      dataIndex: 'event_date',
      key: 'event_date',
          },
    {
      title: 'Date Created',
      dataIndex: 'date_created',
      key: 'date_created',
      
    },
    {
      title: 'Service Status',
      dataIndex: 'service_status',
      key: 'service_status',
      render: (text, record) => (
        <Tooltip title="Click to change status">
          <Tag style={{ cursor: 'pointer' }} color={text === 'pending' ? 'volcano' : 'green'} onClick={() => handleStatusChange(record)}>
            {text.toUpperCase()}
          </Tag>
        </Tooltip>
      )
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type_no',
      key: 'service_type_no',
    },
    {
      title:'Service Name',
      dataIndex:'service_name',
      key:'service_name',
    }
  ];

  const filteredColumns = allColumns.filter(column => selectedColumns.includes(column.dataIndex));

  const filteredData = service_booking && service_booking.filter(item => {
    return item[filter].toString().toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
      <Select
        style={{ width: '200px', marginBottom: '20px' }}
        placeholder="Select filter"
        defaultValue={filter}
        onChange={handleFilterChange}
      >
        {allColumns.filter(column => column.dataIndex !== 'date_created' && column.dataIndex !== 'customer_no' && column.dataIndex !== 'service_type_no').map(column => (
          <Option key={column.dataIndex} value={column.dataIndex}>
            {column.title}
          </Option>
        ))}
      </Select>
      <Search
        placeholder={`Search by ${filter.replace('_', ' ')}`}
        onChange={handleSearchChange}
        style={{ width: 200, marginBottom: 20 }}
      />
      </div>
      <Select
        mode="multiple"
        style={{ width: '100%', marginBottom: '20px' }}
        placeholder="Select columns to display"
        defaultValue={selectedColumns}
        onChange={handleColumnChange}
      >
        {allColumns.map(column => (
          <Option key={column.dataIndex} value={column.dataIndex}>
            {column.title}
          </Option>
        ))}
      </Select>
      
      <Table
        dataSource={filteredData}
        columns={filteredColumns}
        rowKey="booking_id"
        scroll={{ x: '100%' }}
        pagination={{
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </div>
  );
};

export default Submissions;
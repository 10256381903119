import React, { useContext } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import { MyContext } from '../MyProvider';
import axios from 'axios';

const { Option } = Select;

// const main_item = [
//   {
//     "main_name": "Main Course",
//     "main_item_id": 1
//   },
//   {
//     "main_name": "Desserts",
//     "main_item_id": 2
//   }
// ];

const CreateProduct = () => {
  const [form] = Form.useForm();
  const {main_item,sub_itemApi}=useContext(MyContext);
  const onFinish = (values) => {
    // console.log('Form values:', values);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/sub_item`,{"item_name":values.subItem,"main_item_no":values.mainItem}).then((res)=>{message.success(`Add successfully!`);sub_itemApi()
    // console.log(res);
  })
  .catch((err)=>console.log(err,values.mainItem))
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{margin: '0 auto', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}
    >
      <Form.Item
        name="mainItem"
        label="Main Item"
        rules={[{ required: true, message: 'Please select a main item' }]}
      >
        <Select placeholder="Select a main item">
          {main_item.map(item => (
            <Option key={item.main_item_id} value={item.main_item_id}>{item.main_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="subItem"
        label="Sub Item"
        rules={[{ required: true, message: 'Please input a sub item name' }]}
      >
        <Input placeholder="Enter sub item name" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateProduct;

import React, { useContext, useState } from 'react';
import { Form, Select } from 'antd';
import { MyContext } from '../MyProvider';

const { Option } = Select;

export default function MenuItem({ name, index }) {
  const { main_item, sub_item } = useContext(MyContext);
  const [selectedSubItems, setSelectedSubItems] = useState([]);

  const handleMainItemChange = (value) => {
    const filteredSubItems = Array.isArray(sub_item) ? sub_item.filter(el => el.main_item_no === value) : [];
    setSelectedSubItems(filteredSubItems);
  };

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '20px' }}>
      <Form.Item
        name={['events', name, 'items', index, 'mainItem']}
        label="Main Item"
        rules={[{ required: true, message: 'Please select a main item' }]}
        style={{ width: '50%' }}
      >
        <Select placeholder="Select a main item" onChange={handleMainItemChange}>
          {main_item && main_item.map((el, i) => (
            <Option key={i} value={el.main_item_id}>{el.main_name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={['events', name, 'items', index, 'sub_item_no']}
        label="Sub Item"
        rules={[{ required: true, message: 'Please select a sub item' }]}
        style={{ width: '50%' }}
      >
        <Select placeholder="Select a sub item">
          {selectedSubItems && selectedSubItems.map((el, i) => (
            <Option key={i} value={el.sub_item_id}>{el.item_name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

import React, { useState, useContext } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import axios from 'axios';
import { MyContext } from '../MyProvider';

const CreateRecipe = () => {
  const [form] = Form.useForm();
  const { recipeApi } = useContext(MyContext);
  const [isSoldByUs, setIsSoldByUs] = useState(false);

  const onFinish = (values) => {
    const formData = {
      ...values,
      is_sold_by_us: values.is_sold_by_us || false, // Ensure default value
    };

    // console.log('Form values:', formData);

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/recipe.php`, formData, {
      headers: {
        "Accept": "*/*",
        "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
      }
    })
    .then((res) => {
      message.success('Recipe added successfully!');
      form.resetFields();
      setIsSoldByUs(false);
      recipeApi();
    })
    .catch((err) => {
      message.error('Error adding recipe!');
    });
  };

  const handleSoldByUsChange = (e) => {
    setIsSoldByUs(e.target.checked);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#fff'
      }}
    >
      <Form.Item
        name="recipe_name"
        label="Recipe Name"
        rules={[{ required: true, message: 'Please input the recipe name' }]}
      >
        <Input placeholder="Enter recipe name" />
      </Form.Item>

      <Form.Item
        name="ingredients"
        label="Ingredients"
        rules={[{ required: true, message: 'Please input the ingredients' }]}
      >
        <Input.TextArea placeholder="Enter ingredients name" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please input how to prepare the recipe' }]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>

      <Form.Item name="is_sold_by_us" valuePropName="checked">
        <Checkbox onChange={handleSoldByUsChange}>Is sold by us</Checkbox>
      </Form.Item>

      {isSoldByUs && (
        <Form.Item
          name="product_link"
          label="Product Link"
          rules={[{ required: true, message: 'Please input the product link' }]}
        >
          <Input placeholder="Enter product link" />
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateRecipe;

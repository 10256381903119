import React, { useContext, useState } from 'react';
import { Form, Input, Checkbox, Button, DatePicker, message } from 'antd';
import Mealsform from './Mealsform';
import axios from 'axios';
import { MyContext } from '../MyProvider';
import { v4 as uuidv4 } from 'uuid';

const CreateQuotation = () => {
  
  const [form] = Form.useForm();
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
const {quotationApi,customersApi,customers} = useContext(MyContext);

  const onMenuChange = (checkedValues) => {
    setMenu(checkedValues.map(value => ({ name: value, id: uuidv4() })));
  };


  const calculateTotal = () => {
    const values = form.getFieldsValue(true);
    let total = 0;
  
    values.menu.forEach(menuName => {
      const eventDetails = values.events?.[menuName];
      if (eventDetails) {
        const quantity = parseInt(eventDetails.quantity, 10);
        const per_plate = parseFloat(eventDetails.per_plate);
        const servers = parseInt(eventDetails.servers, 10);
        const person_service_charge = parseFloat(values.person_service_charge);
  
        total += (quantity * per_plate) + (servers * person_service_charge);
      }
    });
  
    const transportation_charges = parseFloat(values.transportation_charges);
    total += transportation_charges;
    
    form.setFieldsValue({ total });
  };
  

  const onFinish = async (values) => {
    try {
      setLoading(true);
      // console.log(values);
      const transformedData = {
        customer_name: values.customer_name,
        mobile_id: values.mobile_id,
        email: values.email,
        event_date: values.event_date.format('YYYY-MM-DD'),
        eventOccasion: values.eventOccasion,
        address: values.address,
        transportation_charges: values.transportation_charges,
        person_service_charge: values.person_service_charge,
        total: values.total,
        tiffins: values.menu.includes("Tiffin"),
        meals: values.menu.includes("Meals"),
        snack: values.menu.includes("Snacks"),
        dinner: values.menu.includes("Dinner")
      };

      values.menu.forEach((menuName) => {
        const eventDetails = values.events?.[menuName];
        if (eventDetails) {
          transformedData[menuName] = {
            event_venue: eventDetails.event_venue,
            delivery_time: eventDetails.delivery_time.format('HH:mm'),
            quantity: eventDetails.quantity,
            servers: eventDetails.servers,
            per_plate: eventDetails.per_plate,
            items: eventDetails.items.map(item => ({
              sub_item_no: item.sub_item_no
            }))
          };
        }
      });

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create_quotation.php`, transformedData);
      quotationApi();
      if(customers.find(c => c.mobile_id == transformedData.mobile_id)){
        
      }else{
        customersApi();
      }
      message.success('Quotation created successfully!');
      // console.log('Quotation Data:', transformedData);
    } catch (error) {
      message.error('Failed to create quotation. Please try again.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ margin: '0 auto', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}
    >
      <Form.Item
        name="customer_name"
        label="Customer Name"
        rules={[{ required: true, message: 'Please input the customer name' }]}
      >
        <Input placeholder="Enter customer name" />
      </Form.Item>
      <Form.Item
        name="mobile_id"
        label="Contact Number"
        rules={[{ required: true, message: 'Please input a valid 10 digit contact number',pattern: /^\d{10}$/ }]}
      >
        <Input placeholder="Enter contact number" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email ID"
        rules={[{ required: true, message: 'Please input the email ID' }]}
      >
        <Input placeholder="Enter email ID" />
      </Form.Item>
      <Form.Item
        name="event_date"
        label="Event Date"
        rules={[{ required: true, message: 'Please input the event date' }]}
      >
        <DatePicker style={{ width: '100%' }} placeholder="Enter event date" />
      </Form.Item>
      <Form.Item
        name="eventOccasion"
        label="Event Occasion"
        rules={[{ required: true, message: 'Please input the event occasion' }]}
      >
        <Input placeholder="Enter event occasion" />
      </Form.Item>
      <Form.Item
        name="address"
        label="Address"
        rules={[{ required: true, message: 'Please input the address' }]}
      >
        <Input placeholder="Enter address" />
      </Form.Item>
      <Form.Item
        name="menu"
        label="Menu List"
        rules={[{ required: true, message: 'Please select at least one menu item' }]}
      >
        <Checkbox.Group onChange={onMenuChange}>
          <Checkbox value="Tiffin">Tiffin</Checkbox>
          <Checkbox value="Meals">Meals</Checkbox>
          <Checkbox value="Snacks">Snacks</Checkbox>
          <Checkbox value="Dinner">Dinner</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      
      {menu.map((item) => (
        <Mealsform name={item.name} key={item.id} />
      ))}

      <Form.Item
        name="transportation_charges"
        label="Transportation Charges"
        rules={[{ required: true, message: 'Please input the transportation charges',pattern: /^[0-9]+$/ }]}
      >
        <Input placeholder="Enter Transportation Charges" />
      </Form.Item>
      <Form.Item
        name="person_service_charge"
        label="Person Service Charges"
        rules={[{ required: true, message: 'Please input the Person Service Charges',pattern: /^[0-9]+$/ }]}
      >
        <Input placeholder="Enter Person Service Charges" />
      </Form.Item>
      <Form.Item
        name="total"
        label="Total"
        rules={[{ required: true, message: 'Please input the Total', pattern: /^[0-9]+$/ }]}
      >
        <Input placeholder="Enter Total" readOnly />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={calculateTotal}>
          Calculate Total
        </Button>
      </Form.Item>      
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateQuotation;

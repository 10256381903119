import React, { useContext } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Breadcrumb, Button, Layout, Menu, theme } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import Allroutes from './Allroutes';
import items from './MenuItems';
import './menuStyles.css'; 
import { LoginContext } from '../LoginProvider';

const { Header, Content, Footer } = Layout;

const NewLayout = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { setLoginToken } = useContext(LoginContext);
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className="demo-logo" style={{ color: 'white', fontWeight: 'bold' }}>Bramhanavilas</div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['home']}
            items={items.map(item => ({
              ...item,
              label: <Link to={item.path}>{item.label}</Link>,
              children: item.children ? item.children.map(subItem => ({
                ...subItem,
                label: <Link to={subItem.path}>{subItem.label}</Link>
              })) : null
            }))}
            style={{
              flex: 1,
              minWidth: 0,
            }}
          />
        </Header>
        <Content
          style={{
            padding: '0 12px',
            minHeight: '100%',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
              height: 'auto'
            }}
            items={[
              {
                title: 'Home'
              }, {
                title: 'App'
              },
            ]}
          
          >
            
          </Breadcrumb> 
          
          <Button type="link" onClick={() => setLoginToken("")} style={{ float: 'right',background: '#001529', color: 'white' }}>
            <Link to="/">
            <LogoutOutlined />
            </Link>
          </Button>
          
                <div
            style={{
              padding: 24,
              height: '100%',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Allroutes />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          Bramhanavilas ©2024 Created by WebPerfection
          
        </Footer>
      </Layout>
    </Router>
  );
};

export default NewLayout;

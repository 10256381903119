import React, { useContext } from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import { MyContext } from '../MyProvider';
import axios from 'axios';

const { Option } = Select;


const AddShoppingItems = () => {
  const [form] = Form.useForm();
  const {main_shopping,sub_shoppingApi}=useContext(MyContext);
  const onFinish = (values) => {
    // console.log('Form values:', values);
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/sub_shopping`,{"sub_shopping_name":values.subShopping,"main_shopping_no":values.mainShopping}).then((res)=>{message.success(`Add successfully!`);sub_shoppingApi()
    // console.log(res);
  })
  .catch((err)=>console.log(err,values.mainShopping))
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{margin: '0 auto', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}
    >
      <Form.Item
        name="mainShopping"
        label="Main Shopping"
        rules={[{ required: true, message: 'Please select a main shopping item' }]}
      >
        <Select placeholder="Select a main shopping">
          {main_shopping.map(item => (
            <Option key={item.main_shopping_id} value={item.main_shopping_id}>{item.main_shopping_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="subShopping"
        label="Sub Shopping"
        rules={[{ required: true, message: 'Please input a sub shopping item' }]}
      >
        <Input placeholder="Enter sub shopping item" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddShoppingItems;

import React, { useState, useContext } from 'react';
import { Modal, Form, Input, Checkbox, Tooltip, message, Card, Col, Row, Select, Pagination } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { MyContext } from '../MyProvider';
import axios from 'axios';

const { Option } = Select;

const RecipeList = () => {
  const { recipe, recipeApi } = useContext(MyContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [currentRecipe, setCurrentRecipe] = useState(null);
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [filterColumn, setFilterColumn] = useState('recipe_name');

  const showModal = (type, recipe) => {
    setModalType(type);
    setCurrentRecipe(recipe);
    if (type === 'edit') {
      form.setFieldsValue(recipe);
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/recipe.php`;
        const headers = {
          "Accept": "*/*",
          "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
        };

        if (modalType === 'edit') {
          axios.put(url, { ...values, recipe_id: currentRecipe.recipe_id }, { headers })
            .then(() => {
              message.success('Recipe edited successfully!');
              setIsModalVisible(false);
              recipeApi();
            })
            .catch(() => {
              message.error('Failed to edit recipe!');
              setIsModalVisible(false);
            });
        } else if (modalType === 'delete') {
          axios.delete(url, { data: { recipe_id: currentRecipe.recipe_id }, headers })
            .then(() => {
              message.success('Recipe deleted successfully!');
              setIsModalVisible(false);
              recipeApi();
            })
            .catch(() => {
              message.error('Failed to delete recipe!');
              setIsModalVisible(false);
            });
        }
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handleFilterColumnChange = (value) => {
    setFilterColumn(value);
    setSearchTerm(''); // Reset search term when filter column changes
    setCurrentPage(1); // Reset to first page on filter column change
  };

  const filteredRecipe = recipe && recipe.filter(recipe => 
    recipe[filterColumn]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedRecipe = filteredRecipe.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
        <Select
          defaultValue={filterColumn}
          onChange={handleFilterColumnChange}
          style={{ width: '200px', marginBottom: '20px' }}
        >
          <Option value="recipe_name">Recipe Name</Option>
          <Option value="ingredients">Ingredients</Option>
          <Option value="description">Description</Option>
        </Select>
        <Input.Search
          placeholder={`Search by ${filterColumn.replace('_', ' ')}`}
          onChange={handleSearchChange}
          value={searchTerm}
          style={{ width: 200, marginBottom: 20 }}
        />
      </div>
      <Row gutter={[16, 16]}>
        {paginatedRecipe && paginatedRecipe.map((recipe) => (
          <Col xs={24} sm={12} md={8} lg={6} key={recipe.recipe_id}>
            <Card
              key={recipe.recipe_id}
              style={{ backgroundColor: 'var(--blue-1)' }}
              title={recipe.recipe_name}
              actions={[
                <Tooltip title="View" key="view">
                  <EyeOutlined onClick={() => showModal('view', recipe)} />
                </Tooltip>,
                <Tooltip title="Edit" key="edit">
                  <EditOutlined onClick={() => showModal('edit', recipe)} />
                </Tooltip>,
                <Tooltip title="Delete" key="delete">
                  <DeleteOutlined onClick={() => showModal('delete', recipe)} />
                </Tooltip>
              ]}
            >
              <p>
                <strong>Ingredients:</strong><br />
                <div
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                  }}
                >
                  {recipe.ingredients}
                </div>
              </p>
              <p>
                <strong>Description:</strong><br />
                <div
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                  }}
                >
                  {recipe.description}
                </div>
              </p>
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredRecipe.length}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        style={{ marginTop: 20, textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      />
      <Modal
        title={modalType === 'edit' ? 'Edit Recipe' : modalType === 'view' ? 'View Recipe' : 'Delete Recipe'}
        open={isModalVisible}
        onOk={modalType === 'view' ? handleCancel : handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={modalType === 'view' ? null : undefined}
      >
        {modalType === 'edit' ? (
          <Form form={form} layout="vertical">
            <Form.Item
              name="recipe_name"
              label="Recipe Name"
              rules={[{ required: true, message: 'Please input the recipe name' }]}
            >
              <Input placeholder="Enter recipe name" />
            </Form.Item>
            <Form.Item
              name="ingredients"
              label="Ingredients"
              rules={[{ required: true, message: 'Please input the ingredients' }]}
            >
              <Input.TextArea placeholder="Enter ingredients" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please input the description' }]}
            >
              <Input.TextArea placeholder="Enter description" />
            </Form.Item>
            <Form.Item name="is_sold_by_us" valuePropName="checked">
              <Checkbox>Is sold by us</Checkbox>
            </Form.Item>
            {form.getFieldValue('is_sold_by_us') && (
              <Form.Item
                name="product_link"
                label="Product Link"
                rules={[{ required: true, message: 'Please input the product link' }]}
              >
                <Input placeholder="Enter product link" />
              </Form.Item>
            )}
          </Form>
        ) : modalType === 'view' ? (
          <>
            <p><strong>Recipe Name:</strong> {currentRecipe?.recipe_name}</p>
            <p><strong>Ingredients:</strong><br /> {currentRecipe?.ingredients}</p>
            <p><strong>Description:</strong><br /> {currentRecipe?.description}</p>
            {currentRecipe?.is_sold_by_us && <p><strong>Product Link:</strong> <a href={currentRecipe.product_link} target="_blank" rel="noopener noreferrer">{currentRecipe.product_link}</a></p>}
          </>
        ) : (
          <p>Are you sure you want to delete this recipe?</p>
        )}
      </Modal>
    </div>
  );
};

export default RecipeList;

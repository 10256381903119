import React, { useContext } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { MyContext } from '../MyProvider';

const CreateMainProduct = () => {
  const [form] = Form.useForm();
  const {main_itemApi}=useContext(MyContext);

  const onFinish = (values) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/main_item`,{"main_name":values.mainItem}).then((res)=>{message.success(`Add successfully!`);main_itemApi()
    })
  .catch((err)=>console.log(err,values.mainItem));
  
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{margin: '0 auto', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}
    >
      <Form.Item
        name="mainItem"
        label="Main Item"
        rules={[{ required: true, message: 'Please input the main item name' }]}
      >
        <Input placeholder="Enter main item name" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateMainProduct;

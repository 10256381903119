import React, { useState, useContext, useEffect } from 'react';
import { Collapse, Form, Input, Button, Checkbox, message } from 'antd';
import axios from 'axios';
import { MyContext } from '../MyProvider';

const { Panel } = Collapse;

const ChecklistItems = () => {
  const [form] = Form.useForm();
  const { quotation, quotationApi,checklistApi } = useContext(MyContext);
  const [checkboxState, setCheckboxState] = useState({});
  const [loading, setLoading] = useState(false);

  // Function to update checkbox state from quotation data
  const updateCheckboxState = (quotation) => {
    const state = {};
    quotation.forEach(item => {
      Object.values(item).forEach(subItemsObj => {
        if (subItemsObj.items) {
          subItemsObj.items.forEach(subItem => {
            state[subItem.quotation_item_id] = {
              is_checked: subItem.is_checked === "1",
              quotation_id: item.quotation_id,
              checklist_no: subItem.checklist_no
            };
          });
        }
      });
    });
    setCheckboxState(state);
  };

  // UseEffect to update checkbox state whenever quotation context changes
  useEffect(() => {
    updateCheckboxState(quotation);
  }, [quotation]);

  const onSubmitUpdate = async (quotation_id, values) => {
    const formattedData = {
      quotation_no: quotation_id,
      loaded_person: values.loaded_person,
      servers_assign: values.servers_assign,
      items: Object.keys(checkboxState)
        .filter(key => checkboxState[key].is_checked && checkboxState[key].quotation_id === quotation_id && checkboxState[key].checklist_no == null)
        .map(key => ({
          quotation_item_id: key,
          is_checked: checkboxState[key].is_checked ? "1" : "0"
        }))
    };
// console.log(formattedData.items);
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/checklist_update.php`, formattedData);
      quotationApi();
      checklistApi();
      message.success(response.data.message);
      form.resetFields();
    } catch (error) {
      message.error('Failed to update checklist. Please try again.');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeUpdate = (id, checked) => {
    setCheckboxState(prevState => ({
      ...prevState,
      [id]: { ...prevState[id], is_checked: checked }
    }));
    // console.log(checkboxState);
  };

  const renderSubItems = (subItems = []) => {
    return subItems.map((item) => (
      <div key={item.sub_item_no}>
        <Checkbox
          disabled={item.is_checked === "1"}
          checked={checkboxState[item.quotation_item_id]?.is_checked}
          onChange={(e) => onChangeUpdate(item.quotation_item_id, e.target.checked)}
        >
          {item.sub_item_name}
        </Checkbox>
      </div>
    ));
  };

  const renderMainItems = (mainItems = []) => {
    const groupedItems = mainItems.reduce((acc, item) => {
      if (!acc[item.main_item_name]) {
        acc[item.main_item_name] = [];
      }
      acc[item.main_item_name].push(item);
      return acc;
    }, {});

    return Object.entries(groupedItems).map(([mainItemName, items]) => (
      <Collapse key={mainItemName}>
        <Panel header={mainItemName} key={mainItemName} style={{ backgroundColor: '#1890ff'}}>
          {renderSubItems(items)}
        </Panel>
      </Collapse>
    ));
  };

  const renderFoodTypes = (foodTypes) => {
    return (
      <Collapse>
        {Object.entries(foodTypes).map(([type, details]) => (
          (details.quantity > 0 || details.servers > 0 || details.per_plate > 0 || details.items.length > 0) && (
            <Panel header={type.charAt(0).toUpperCase() + type.slice(1)} key={type} style={{ backgroundColor: '#1890ff'}}>
              {renderMainItems(details.items)}
            </Panel>
          )
        ))}
      </Collapse>
    );
  };

  const renderQuotationPanels = () => {
    return quotation.map((item) => (
      <Collapse key={item.quotation_id}>
        <Panel header={`Quotation ID: ${item.quotation_id}`} key={item.quotation_id} style={{ backgroundColor: '#1890ff'}}>
          <div>
            <p><strong>Customer Name:</strong> {item.customer_details.customer_name}</p>
            <p><strong>Customer ID:</strong> {item.customer_details.customer_no}</p>
            <p><strong>Mobile:</strong> {item.customer_details.customer_mobile}</p>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => onSubmitUpdate(item.quotation_id, values)}
          >
            <Form.Item
              name="servers_assign"
              label="Servers Assigned"
              rules={[{ required: true, message: 'Please input servers assigned!' }]}
            >
              <Input placeholder="Servers Assigned" />
            </Form.Item>
            <Form.Item
              name="loaded_person"
              label="Loaded Person"
              rules={[{ required: true, message: 'Please input loaded person!' }]}
            >
              <Input placeholder="Loaded Person" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
            </Form.Item>
          </Form>
          {renderFoodTypes({
            Tiffins: item.Tiffins,
            Meals: item.Meals,
            Snacks: item.Snacks,
            Dinner: item.Dinner,
          })}
        </Panel>
      </Collapse>
    ));
  };

  return (
    <div>
      {renderQuotationPanels()}
    </div>
  );
};

export default ChecklistItems;

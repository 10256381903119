import React, { useState, useContext } from 'react';
import { Card, Row, Col, Tooltip, Modal, Button, Form, Input, DatePicker, message, Select, Pagination } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MyContext } from '../MyProvider';
import axios from 'axios';
import { Link } from 'react-router-dom';


const { Option } = Select;

const ShoppingList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [currentShopping, setCurrentShopping] = useState(null);
  const [form] = Form.useForm();
  const { shopping_list, shopping_listApi } = useContext(MyContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [filterColumn, setFilterColumn] = useState('customer_name');


  const showModal = (type, shopping) => {
    setModalType(type);
    setCurrentShopping(shopping);
    if (type === 'edit') {
      form.setFieldsValue({
        ...shopping,
        event_date: shopping.event_date ? dayjs(shopping.event_date, 'YYYY-MM-DD') : null,
        ...shopping.vegetable_obj,
        ...shopping.pulses_obj
      });
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (modalType === 'delete') {
      axios.delete(`${process.env.REACT_APP_API_BASE_URL}/shoppinglist.php`, { data: { shopping_id: currentShopping.shopping_id } }, {
        headers: {
          "Accept": "*/*",
          "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
        }
      })
      .then(() => {
        message.success('Shopping list deleted successfully!');
        shopping_listApi();
      })
      .catch(() => {
        message.error('Error deleting shopping list!');
      })
      .finally(() => {
        setIsModalVisible(false);
      });
    } else if (modalType === 'edit') {
      form.validateFields().then((values) => {
        const updatedVegetableObj = Object.fromEntries(Object.entries(values).filter(([key]) => currentShopping.vegetable_obj.hasOwnProperty(key)));
        const updatedPulsesObj = Object.fromEntries(Object.entries(values).filter(([key]) => currentShopping.pulses_obj.hasOwnProperty(key)));

        const data = {
          ...currentShopping,
          event_date: values.event_date.format('YYYY-MM-DD'),
          vegetable_obj: updatedVegetableObj,
          pulses_obj: updatedPulsesObj,
        };

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/shoppinglist.php`, data, {
          headers: {
            "Accept": "*/*",
            "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
          }
        })
        .then(() => {
          message.success('Shopping list updated successfully!');
          shopping_listApi();
        })
        .catch(() => {
          message.error('Error updating shopping list!');
        })
        .finally(() => {
          setIsModalVisible(false);
        });
      }).catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handleFilterColumnChange = (value) => {
    setFilterColumn(value);
    setSearchTerm(''); // Reset search term when filter column changes
    setCurrentPage(1); // Reset to first page on filter column change
  };

  const filteredshopping_list = shopping_list && shopping_list.filter(shopping_list => 
    shopping_list[filterColumn]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedshopping_list = filteredshopping_list.slice((currentPage - 1) * pageSize, currentPage * pageSize);


  const renderObjFields = (obj) => {
    return Object.entries(obj).map(([key, value], index) => (
      <Form.Item key={index} label={key} name={key} initialValue={value}>
        <Input />
      </Form.Item>
    ));
  };

  return (
    <div>
    <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
        <Select
          defaultValue={filterColumn}
          onChange={handleFilterColumnChange}
          style={{ width: '200px', marginBottom: '20px' }}
        >
          <Option value="customer_name">Customer Name</Option>
          
          <Option value="mobile_id">Mobile Number</Option>
          <Option value="event_date">Event Date</Option>
        </Select>
        <Input.Search
          placeholder={`Search by ${filterColumn.replace('_', ' ')}`}
          onChange={handleSearchChange}
          value={searchTerm}
          style={{ width: 200, marginBottom: 20 }}
        />
      </div>
      <Row gutter={[16, 16]}>
        {paginatedshopping_list && paginatedshopping_list.map((shopping) => (
          <Col xs={24} sm={12} md={8} lg={6} key={shopping.shopping_id}>
            <Card
              style={{ backgroundColor: 'var(--blue-1)' }}
              title={shopping.customer_name}
              extra={
                <Tooltip title="View User">
                  <Link to={`/users/details/${shopping.customer_no}`}>
                  <Button
                    shape="circle"
                    icon={<EyeOutlined />}
                    
                  />
                  </Link>
                </Tooltip>
              }
              actions={[
                <Tooltip title="View" key="view">
                  <EyeOutlined onClick={() => showModal('view', shopping)} />
                </Tooltip>,
                <Tooltip title="Edit" key="edit">
                  <EditOutlined onClick={() => showModal('edit', shopping)} />
                </Tooltip>,
                <Tooltip title="Delete" key="delete">
                  <DeleteOutlined onClick={() => showModal('delete', shopping)} />
                </Tooltip>
              ]}
            >
              <span><strong>Mobile Number:</strong> {shopping.mobile_id}</span><br />
              <span><strong>Event Date:</strong> {shopping.event_date}</span><br />
              <span><strong>Vegetables:</strong> {Object.keys(shopping.vegetable_obj).length}{" items"}</span><br />
              <span><strong>Pulses:</strong> {Object.keys(shopping.pulses_obj).length}{" items"}</span>
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredshopping_list.length}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        style={{ marginTop: 20, textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      />
      <Modal
        title={modalType === 'edit' ? 'Edit Shopping List' : modalType === 'view' ? 'View Shopping List' : 'Delete Shopping List'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={modalType === 'view' ? null : [
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {modalType === 'delete' ? 'Delete' : 'OK'}
          </Button>
        ]}
      >
        {modalType === 'view' ? (
          <>
            <p><strong>Customer Name:</strong> {currentShopping?.customer_name}</p>
            <p><strong>Mobile Number:</strong> {currentShopping?.mobile_id}</p>
            <p><strong>Event Date:</strong> {currentShopping?.event_date}</p>
            <span><strong>Vegetables:</strong></span>
            <ul>
    {Object.entries(currentShopping.vegetable_obj).map(([key, value], index) => (
      <li key={index}>{key}: {value}</li>
    ))}
  </ul>
  <span><strong>Pulses:</strong></span>
  <ul>
    {Object.entries(currentShopping.pulses_obj).map(([key, value], index) => (
      <li key={index}>{key}: {value}</li>
    ))}
  </ul>
          </>
        ) : modalType === 'edit' ? (
          <Form form={form} layout="vertical">
            <Form.Item label="Event Date" name="event_date" rules={[{ required: true, message: 'Please select an event date!' }]}>
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            </Form.Item>
            <h3>Vegetables</h3>
            {renderObjFields(currentShopping.vegetable_obj)}
            <h3>Pulses</h3>
            {renderObjFields(currentShopping.pulses_obj)}
          </Form>
        ) : (
          <p>Are you sure you want to delete this shopping list?</p>
        )}
      </Modal>
    </div>
  );
};

export default ShoppingList;

import React, { useContext } from 'react';
import { Table, Tooltip, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MyContext } from '../MyProvider';
import { Link } from 'react-router-dom';

const BirthdayList = () => {
  const { customers } = useContext(MyContext);

  // Sort customers by date of birth in ascending order from the current date
  const sortedCustomers = customers && customers.filter(c => c.date_of_birth).slice().sort((a, b) => {
    const currentYear = dayjs().year();
    const today = dayjs();

    // Calculate the next birthday for each customer
    const aNextBirthday = dayjs(a.date_of_birth).year(currentYear);
    const bNextBirthday = dayjs(b.date_of_birth).year(currentYear);

    // Adjust for past dates
    if (aNextBirthday.isBefore(today)) {
      aNextBirthday.add(1, 'year');
    }
    if (bNextBirthday.isBefore(today)) {
      bNextBirthday.add(1, 'year');
    }

    return aNextBirthday - bNextBirthday;
  });

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_id',
      key: 'mobile_id',
    },
    {
      title: 'Birthday',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      render: (text) => dayjs(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Link to={`/users/details/${record.customer_id}`}>
        <Tooltip title="View">
          <Button shape="circle" icon={<EyeOutlined />}/>
        </Tooltip>
        </Link>
      ),
    },
  ];

  
  return (
    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <Table
        dataSource={sortedCustomers}
        columns={columns}
        rowKey="mobile_id"
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </div>
  );
};

export default BirthdayList;

import React, { useState } from 'react';
import { Form, Input, Button, TimePicker } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutlined } from '@ant-design/icons';
import MenuItem from './MenuItem';

export default function Mealsform({ name }) {
  const [items, setItems] = useState([{ id: uuidv4() }]);

  const addItem = () => {
    setItems([...items, { id: uuidv4() }]);
  };

  const deleteItem = (id) => {
    setItems(items.filter(item => item.id !== id));
  };

  return (
    <>
      <h4>{name}</h4>
      <Form.Item
        name={['events', name, 'event_venue']}
        label="Event Venue"
        rules={[{ required: true, message: 'Please input the event venue' }]}
      >
        <Input placeholder="Enter event venue" />
      </Form.Item>
      
      <Form.Item
        name={['events', name, 'delivery_time']}
        label="Delivery Time"
        rules={[{ required: true, message: 'Please select the delivery time' }]}
      >
        <TimePicker placeholder="Select delivery time" format="HH:mm" />
      </Form.Item>
      <Form.Item
        name={['events', name, 'quantity']}
        label="Quantity"
        rules={[{ required: true, message: 'Please input the quantity', pattern: /^[0-9]*$/ }]}
      >
        <Input placeholder="Enter quantity" />
      </Form.Item>
      <Form.Item
        name={['events', name, 'servers']}
        label="Servers"
        rules={[{ required: true, message: 'Please input the number of servers', pattern: /^[0-9]*$/ }]}
      >
        <Input placeholder="Enter number of servers" />
      </Form.Item>
      <Form.Item
        name={['events', name, 'per_plate']}
        label="Per Plate"
        rules={[{ required: true, message: 'Please input the number of Per Plate', pattern: /^[0-9]*$/ }]}
      >
        <Input placeholder="Enter number of Per Plate" />
      </Form.Item>
      {items.map((item, index) => (
        <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
          <MenuItem name={name} index={index} />
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => deleteItem(item.id)}
            style={{ marginLeft: '8px' }}
          />
        </div>
      ))}
      <Form.Item>
        <Button type="primary" onClick={addItem}>
          Add Items
        </Button>
      </Form.Item>
    </>
  );
}

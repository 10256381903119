import React, { useContext } from 'react';
import './App.css';
import Login from './Login';
import NewLayout from './layout/NewLayout';
import { MyProvider } from './MyProvider';
import { LoginProvider, LoginContext } from './LoginProvider';

const App = () => {
  const { loginToken } = useContext(LoginContext);

  if (loginToken === "") {
    return <Login />;
  }

  return (
    <MyProvider>
      <NewLayout />
    </MyProvider>
  );
};

const RootApp = () => (
  <LoginProvider>
    <App />
  </LoginProvider>
);

export default RootApp;

import axios from "axios";


export async function fetchData(url) {
    try{
        const response = await axios.get(url,{headers:{"Accept": "*/*",
            "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
            }});

        
        const responseData=await response.data;
        return responseData;
    }catch(error){
        console.log(error);
    }
}
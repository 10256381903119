import React, { useContext } from 'react';
import { Form, Input, Button, DatePicker, message } from 'antd';
import axios from 'axios';
import { MyContext } from '../MyProvider';

const { TextArea } = Input;

const CreateUser = () => {
  const [form] = Form.useForm();
  const { customersApi } = useContext(MyContext);

  const onFinish = (values) => {
    // console.log('Form values:', values);

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/customers.php`, values, {
      headers: {
        "Accept": "*/*",
        "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)",
      }
    })
    .then((res) => {
      message.success('User added successfully!');
      form.resetFields();
      customersApi();
    })
    .catch((err) => {
      message.error('Error adding user!');
      console.error('Error:', err);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Please check the form for errors!');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#fff'
      }}
    >
      <Form.Item
        name="mobile_id"
        label="Mobile Number"
        rules={[
          { required: true, message: 'Please input your mobile number' },
          { pattern: /^\d{10}$/, message: 'Please input a valid 10-digit mobile number' }
        ]}
      >
        <Input type="tel" placeholder="Enter mobile number" maxLength={10} />
      </Form.Item>

      <Form.Item
        name="customer_name"
        label="Customer Name"
        rules={[{ required: true, message: 'Please input your name' }]}
      >
        <Input placeholder="Enter customer name" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email ID"
        rules={[{ type: 'email', message: 'Please input a valid email' }]}
      >
        <Input placeholder="Enter email ID" />
      </Form.Item>

      <Form.Item
        name="address"
        label="Full Address"
      >
        <TextArea placeholder="Enter full address" autoSize={{ minRows: 2, maxRows: 6 }} />
      </Form.Item>

      <Form.Item
        name="date_of_birth"
        label="Date of Birth"
      >
        <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" placeholder="Select date of birth" />
      </Form.Item>


      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateUser;

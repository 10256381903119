import React, { useContext, useState } from 'react';
import { Button, Input, Form, Row, Col, DatePicker, Select, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { MyContext } from '../MyProvider';

const { Option } = Select;

const CreateShopping = () => {
  const [form] = Form.useForm();
  const [vegetables, setVegetables] = useState([{ name: '', qty: '' }]);
  const [pulses, setPulses] = useState([{ name: '', qty: '' }]);
  const { customersApi, customers, shopping_listApi, sub_shopping } = useContext(MyContext);

  const handleAdd = (type) => {
    if (type === 'vegetables') {
      setVegetables([...vegetables, { name: '', qty: '' }]);
    } else {
      setPulses([...pulses, { name: '', qty: '' }]);
    }
  };

  const handleRemove = (index, type) => {
    if (type === 'vegetables') {
      const newVegetables = vegetables.filter((_, i) => i !== index);
      setVegetables(newVegetables);
    } else {
      const newPulses = pulses.filter((_, i) => i !== index);
      setPulses(newPulses);
    }
  };

  const handleChange = (index, type, key, value) => {
    if (type === 'vegetables') {
      const newVegetables = [...vegetables];
      newVegetables[index][key] = value;
      setVegetables(newVegetables);
    } else {
      const newPulses = [...pulses];
      newPulses[index][key] = value;
      setPulses(newPulses);
    }
  };

  const onFinish = (values) => {
    const vegetableObj = vegetables.reduce((acc, item) => {
      acc[item.name] = item.qty;
      return acc;
    }, {});

    const pulsesObj = pulses.reduce((acc, item) => {
      acc[item.name] = item.qty;
      return acc;
    }, {});

    const data = {
      ...values,
      event_date: values.event_date ? values.event_date.format('YYYY-MM-DD') : '',
      pulses_obj: pulsesObj,
      vegetable_obj: vegetableObj,
    };

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/shoppinglist.php`, data)
      .then((res) => {
        message.success('Shopping list created successfully!');
        shopping_listApi();
        if (customers.find(customer => customer.customer_name === values.name)) {
          // Do something if the customer exists
        } else {
          customersApi();
        }
        form.resetFields();
        setVegetables([{ name: '', qty: '' }]);
        setPulses([{ name: '', qty: '' }]);
      })
      .catch((err) => {
        message.error('Error creating shopping list!');
        console.error('Error:', err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Please check the form for errors!');
    console.log('Failed:', errorInfo);
  };

  const getDropdownOptions = (type) => {
    return sub_shopping
      .filter(item => item.main_shopping_no === (type === 'vegetables' ? 1 : 2))
      .map(item => <Option key={item.sub_shopping_id} value={item.sub_shopping_name}>{item.sub_shopping_name}</Option>);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#fff'
      }}
    >
      <Form.Item
        name="name"
        label="Customer Name"
        rules={[{ required: true, message: 'Please input the customer name' }]}
      >
        <Input placeholder="Enter customer name" />
      </Form.Item>

      <Form.Item
        name="customer_no"
        label="Mobile Number"
        rules={[
          { required: true, message: 'Please input the mobile number' },
          { pattern: /^\d{10}$/, message: 'Please input a valid 10-digit mobile number' }
        ]}
      >
        <Input type="tel" placeholder="Enter mobile number" maxLength={10} />
      </Form.Item>

      <Form.Item
        name="event_date"
        label="Event Date"
        rules={[{ required: true, message: 'Please select the event date' }]}
      >
        <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" placeholder="Select event date" />
      </Form.Item>

      <div>
        <h3>Vegetables</h3>
        {vegetables.map((item, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <Select
                placeholder="Select Vegetable"
                value={item.name}
                onChange={(value) => handleChange(index, 'vegetables', 'name', value)}
                style={{ width: '100%' }}
              >
                {getDropdownOptions('vegetables')}
              </Select>
            </Col>
            <Col span={8}>
              <Input
                placeholder="Quantity"
                value={item.qty}
                onChange={(e) => handleChange(index, 'vegetables', 'qty', e.target.value)}
              />
            </Col>
            <Col span={2}>
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(index, 'vegetables')}
              />
            </Col>
          </Row>
        ))}
        <Button type="dashed" onClick={() => handleAdd('vegetables')}>
          Add Vegetable
        </Button>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3>Pulses</h3>
        {pulses.map((item, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <Select
                placeholder="Select Pulse"
                value={item.name}
                onChange={(value) => handleChange(index, 'pulses', 'name', value)}
                style={{ width: '100%' }}
              >
                {getDropdownOptions('pulses')}
              </Select>
            </Col>
            <Col span={8}>
              <Input
                placeholder="Quantity"
                value={item.qty}
                onChange={(e) => handleChange(index, 'pulses', 'qty', e.target.value)}
              />
            </Col>
            <Col span={2}>
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(index, 'pulses')}
              />
            </Col>
          </Row>
        ))}
        <Button type="dashed" onClick={() => handleAdd('pulses')}>
          Add Pulse
        </Button>
      </div>

      <Form.Item style={{ marginTop: '20px' }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateShopping;

import React, { useContext, useState } from 'react';
import { Collapse, Button, Modal, Form, Input, message, Tooltip, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { MyContext } from '../MyProvider';
import axios from 'axios';

const { Panel } = Collapse;

const ProductList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [form] = Form.useForm();
  
  const { main_item, sub_item, sub_itemApi } = useContext(MyContext);

  const showModal = (type, item) => {
    setModalType(type);
    setCurrentItem(item);
    if (type === 'edit') {
      form.setFieldsValue(item);
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        const { sub_item_id, main_item_no } = currentItem;
        const url = `${process.env.REACT_APP_API_BASE_URL}/sub_item`;
        const request = modalType === 'edit'
          ? axios.put(url, { item_name: values.item_name, sub_item_id, main_item_no }, { headers: { "Accept": "*/*", "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)" } })
          : axios.delete(url, { data: { sub_item_id } });
        
        request
          .then(() => {
            message.success(`${modalType === 'edit' ? 'Edited' : 'Deleted'} successfully!`);
            sub_itemApi();
            setIsModalVisible(false);
            form.resetFields();
          })
          .catch(err => {
            message.error(`Failed to ${modalType === 'edit' ? 'edit' : 'delete'} the item.`);
            setIsModalVisible(false);
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    {
      title: 'Sub Item',
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, item) => (
        <>
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} onClick={() => showModal('edit', item)} style={{ marginRight: '8px' }} />
          </Tooltip>
          {/* <Tooltip title="Delete">
            <Button icon={<DeleteOutlined />} onClick={() => showModal('delete', item)} />
          </Tooltip> */}
        </>
      ),
    },
  ];

  return (
    <div style={{ margin: '0 auto', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}>
      <Collapse>
        {main_item.length > 0 && main_item.map(main => (
          <Panel header={main.main_name} key={main.main_item_id} style={{ backgroundColor: '#1890ff'}}>
            <Table
              dataSource={sub_item && sub_item.filter(sub => sub.main_item_no === main.main_item_id)}
              columns={columns}
              rowKey="sub_item_id"
              pagination={false}
            />
          </Panel>
        ))}
      </Collapse>

      <Modal
        title={modalType === 'edit' ? 'Edit Item' : 'Delete Item'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalType === 'edit' ? (
          <Form form={form} layout="vertical">
            <Form.Item
              name="item_name"
              label="Item Name"
              rules={[{ required: true, message: 'Please input the item name' }]}
            >
              <Input placeholder="Enter item name" />
            </Form.Item>
          </Form>
        ) : (
          <p>Are you sure you want to delete this item?</p>
        )}
      </Modal>
    </div>
  );
};

export default ProductList;

import React, { useState, useContext } from 'react';
import { Card, Row, Col, Tooltip, Modal, Button, Form, Input, DatePicker, message, Pagination, Select } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MyContext } from '../MyProvider';
import { Link } from 'react-router-dom';
import axios from 'axios';

const { Option } = Select;

const UserList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [form] = Form.useForm();
  const { customers, customersApi } = useContext(MyContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [filterColumn, setFilterColumn] = useState('customer_name');

  const showModal = (type, customer) => {
    setModalType(type);
    setCurrentCustomer(customer);
    if (type === 'edit') {
      form.setFieldsValue({
        ...customer,
        date_of_birth: customer.date_of_birth ? dayjs(customer.date_of_birth, 'YYYY-MM-DD') : null
      });
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (modalType === 'delete') {
      axios.delete(`${process.env.REACT_APP_API_BASE_URL}/customers.php`, { data: { customer_id: currentCustomer.customer_id } }, {
        headers: {
          "Accept": "*/*",
          "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
        }
      })
      .then(() => {
        message.success('Customer deleted successfully!');
        customersApi();
      })
      .catch(() => {
        message.error('Error deleting customer!');
      })
      .finally(() => {
        setIsModalVisible(false);
      });
    } else if (modalType === 'edit') {
      form.validateFields().then((values) => {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/customers.php`, { ...currentCustomer, ...values, date_of_birth: values.date_of_birth.format('YYYY-MM-DD') }, {
          headers: {
            "Accept": "*/*",
            "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
          }
        })
        .then(() => {
          message.success('Customer updated successfully!');
          customersApi();
        })
        .catch(() => {
          message.error('Error updating customer!');
        })
        .finally(() => {
          setIsModalVisible(false);
        });
      }).catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handleFilterColumnChange = (value) => {
    setFilterColumn(value);
    setSearchTerm(''); // Reset search term when filter column changes
    setCurrentPage(1); // Reset to first page on filter column change
  };

  const filteredCustomers = customers && customers.filter(customer => 
    customer[filterColumn]?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedCustomers = filteredCustomers.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
        <Select
          defaultValue={filterColumn}
          onChange={handleFilterColumnChange}
          style={{ width: '200px', marginBottom: '20px' }}
        >
          <Option value="customer_name">Customer Name</Option>
          <Option value="email">Email</Option>
          <Option value="mobile_id">Mobile Number</Option>
          <Option value="address">Address</Option>
        </Select>
        <Input.Search
          placeholder={`Search by ${filterColumn.replace('_', ' ')}`}
          onChange={handleSearchChange}
          value={searchTerm}
          style={{ width: 200, marginBottom: 20 }}
        />
      </div>
      <Row gutter={[16, 16]}>
        {paginatedCustomers && paginatedCustomers.map((customer) => (
          <Col xs={24} sm={12} md={8} lg={6} key={customer.customer_id}>
            <Card
              style={{ backgroundColor: 'var(--blue-1)' }}
              hoverable
              
              title={customer.customer_name}
              extra={
                <Link to={`/users/details/${customer.customer_id}`}>
                <Tooltip title="View User">
                  <Button
                    shape="circle"
                    icon={<UserOutlined />}
                    
                  />
                </Tooltip>
                </Link>
              }
              actions={[
                <Tooltip title="View" key="view">
                  <EyeOutlined onClick={() => showModal('view', customer)} />
                </Tooltip>,
                <Tooltip title="Edit" key="edit">
                  <EditOutlined onClick={() => showModal('edit', customer)} />
                </Tooltip>,
                <Tooltip title="Delete" key="delete">
                  <DeleteOutlined onClick={() => showModal('delete', customer)} />
                </Tooltip>
              ]}
            >
              <span><strong>Mobile Number:</strong> {customer.mobile_id}</span><br />
              <span><strong>Email:</strong> {customer.email}</span><br />
              <span><strong>Date of Birth:</strong> {customer.date_of_birth}</span><br />
              <span><strong>Address:</strong><div
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                  }}
                > {customer.address}
                </div>
                </span>
              
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredCustomers.length}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        style={{ marginTop: 20, textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      />
      <Modal
        title={modalType === 'edit' ? 'Edit Customer' : modalType === 'view' ? 'View Customer' : 'Delete Customer'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={modalType === 'view' ? null : [
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {modalType === 'delete' ? 'Delete' : 'OK'}
          </Button>
        ]}
      >
        {modalType === 'view' ? (
          <>
            <p><strong>Customer Name:</strong> {currentCustomer?.customer_name}</p>
            <p><strong>Mobile Number:</strong> {currentCustomer?.mobile_id}</p>
            <p><strong>Email:</strong> {currentCustomer?.email}</p>
            <p><strong>Address:</strong> {currentCustomer?.address}</p>
            <p><strong>Date of Birth:</strong> {currentCustomer?.date_of_birth}</p>
          </>
        ) : modalType === 'edit' ? (
          <Form form={form} layout="vertical">
            <Form.Item name="customer_name" label="Customer Name" rules={[{ required: true, message: 'Please input the customer name!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Please input a valid email' }]}>
              <Input />
            </Form.Item>  
            <Form.Item name="address" label="Address" >
              <Input />
            </Form.Item>
            <Form.Item name="date_of_birth" label="Date of Birth">
              <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
            </Form.Item>
          </Form>
        ) : (
          <p>Are you sure you want to delete this customer?</p>
        )}
      </Modal>
    </div>
  );
};

export default UserList;
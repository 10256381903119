import React, { useContext, useState } from 'react';
import { Collapse, Button, Modal, Form, Input, message, Tooltip, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { MyContext } from '../MyProvider';
import axios from 'axios';

const { Panel } = Collapse;

const ShoppingListItems = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [currentItem, setCurrentItem] = useState(null);
  const [form] = Form.useForm();
  
  const { main_shopping, sub_shopping, sub_shoppingApi } = useContext(MyContext);

  const showModal = (type, item) => {
    setModalType(type);
    setCurrentItem(item);
    if (type === 'edit') {
      form.setFieldsValue(item);
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        const { sub_shopping_id, main_shopping_no } = currentItem;
        const url = `${process.env.REACT_APP_API_BASE_URL}/sub_shopping`;
        const request = modalType === 'edit'
          ? axios.put(url, { sub_shopping_name: values.sub_shopping_name, sub_shopping_id, main_shopping_no }, { headers: { "Accept": "*/*", "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)" } })
          : axios.delete(url, { data: { sub_shopping_id } });
        
        request
          .then(() => {
            message.success(`${modalType === 'edit' ? 'Edited' : 'Deleted'} successfully!`);
            sub_shoppingApi();
            setIsModalVisible(false);
            form.resetFields();
          })
          .catch(err => {
            message.error(`Failed to ${modalType === 'edit' ? 'edit' : 'delete'} the item.`);
            setIsModalVisible(false);
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const columns = [
    {
      title: 'Sub Shopping Name',
      dataIndex: 'sub_shopping_name',
      key: 'sub_shopping_name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, item) => (
        <>
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} onClick={() => showModal('edit', item)} style={{ marginRight: '8px' }} />
          </Tooltip>
          {/* <Tooltip title="Delete">
            <Button icon={<DeleteOutlined />} onClick={() => showModal('delete', item)} />
          </Tooltip> */}
        </>
      ),
    },
  ];

  return (
    <div style={{ margin: '0 auto', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}>
      <Collapse>
        {main_shopping.length > 0 && main_shopping.map(main => (
          <Panel header={main.main_shopping_name} key={main.main_shopping_id} style={{ backgroundColor: '#1890ff'}}>
            <Table
              dataSource={sub_shopping && sub_shopping.filter(sub => sub.main_shopping_no === main.main_shopping_id)}
              columns={columns}
              rowKey="sub_shopping_id"
              pagination={false}
            />
          </Panel>
        ))}
      </Collapse>

      <Modal
        title={modalType === 'edit' ? 'Edit Item' : 'Delete Item'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalType === 'edit' ? (
          <Form form={form} layout="vertical">
            <Form.Item
              name="sub_shopping_name"
              label="Sub Shopping Name"
              rules={[{ required: true, message: 'Please input the item name' }]}
            >
              <Input placeholder="Enter item name" />
            </Form.Item>
          </Form>
        ) : (
          <p>Are you sure you want to delete this item?</p>
        )}
      </Modal>
    </div>
  );
};

export default ShoppingListItems;

import React, { useContext, useState } from 'react';
import { Card, Button, Row, Col, Tooltip, Modal, Pagination, Space, Form, Select, Input, DatePicker, message } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { MyContext } from '../MyProvider';
import axios from 'axios';
import dayjs from 'dayjs';

const { Option } = Select;
const MasterData = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(''); // To store which section to show in the modal
  const [currentQuotation, setCurrentQuotation] = useState(null);
const [form] = Form.useForm();
  const { quotation, quotationApi } = useContext(MyContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [filterColumn, setFilterColumn] = useState('quotation_id');
  const [modalType, setModalType] = useState('');
  const startDate = dayjs().format('YYYY-MM-DD');
  const endDate = dayjs().add(7, 'days').format('YYYY-MM-DD');
  console.log(startDate, endDate);
  // const quotation=[
  //   {
  //   "food_types": {
  //   "meals": "1",
  //   "dinner": "0",
  //   "snacks": "0",
  //   "tiffins": "1"
  //   },
  //   "customer_details": {
  //   "customer_no": "38",
  //   "customer_name": "Akhil yad",
  //   "customer_mobile": "9999888877"
  //   },
  //   "total": "10000.00",
  //   "event_date": "2024-07-31",
  //   "date_created": "2024-07-29",
  //   "quotation_id": "36",
  //   "quote_status": "pending",
  //   "person_service_charge": "90.00",
  //   "transportation_charges": "80.00",
  //   "Tiffins": {
  //   "quantity": "50",
  //   "servers": "60",
  //   "per_plate": "70.00",
  //   "items": [
  //   {
  //   "quotation_item_id": "37",
  //   "sub_item_no": "4",
  //   "sub_item_name": "Panner Manchuria",
  //   "main_item_name": "Main Course",
  //   "is_checked": "1",
  //   "checklist_no": "21"
  //   }
  //   ]
  //   },
  //   "Meals": {
  //   "quantity": "20",
  //   "servers": "30",
  //   "per_plate": "40.00",
  //   "items": [
  //   {
  //   "quotation_item_id": "39",
  //   "sub_item_no": "1",
  //   "sub_item_name": "Grilled Chicken",
  //   "main_item_name": "Main Course",
  //   "is_checked": "1",
  //   "checklist_no": "21"
  //   },
  //   {
  //   "quotation_item_id": "38",
  //   "sub_item_no": "2",
  //   "sub_item_name": "Chocolate Cake",
  //   "main_item_name": "Desserts",
  //   "is_checked": "0",
  //   "checklist_no": null
  //   }
  //   ]
  //   },
  //   "Snacks": {
  //   "quantity": 0,
  //   "servers": 0,
  //   "per_plate": 0,
  //   "items": []
  //   },
  //   "Dinner": {
  //   "quantity": 0,
  //   "servers": 0,
  //   "per_plate": 0,
  //   "items": []
  //   }
  //   },
  //   {
  //   "food_types": {
  //   "meals": "1",
  //   "dinner": "0",
  //   "snacks": "0",
  //   "tiffins": "1"
  //   },
  //   "customer_details": {
  //   "customer_no": "38",
  //   "customer_name": "Akhil yad",
  //   "customer_mobile": "9999888877"
  //   },
  //   "total": "10000.00",
  //   "event_date": "2024-07-31",
  //   "date_created": "2024-07-30",
  //   "quotation_id": "37",
  //   "quote_status": "pending",
  //   "person_service_charge": "90.00",
  //   "transportation_charges": "80.00",
  //   "Tiffins": {
  //   "quantity": "50",
  //   "servers": "60",
  //   "per_plate": "70.00",
  //   "items": [
  //   {
  //   "quotation_item_id": "40",
  //   "sub_item_no": "4",
  //   "sub_item_name": "Panner Manchuria",
  //   "main_item_name": "Main Course",
  //   "is_checked": "0",
  //   "checklist_no": null
  //   }
  //   ]
  //   },
  //   "Meals": {
  //   "quantity": "20",
  //   "servers": "30",
  //   "per_plate": "40.00",
  //   "items": [
  //   {
  //   "quotation_item_id": "42",
  //   "sub_item_no": "1",
  //   "sub_item_name": "Grilled Chicken",
  //   "main_item_name": "Main Course",
  //   "is_checked": "0",
  //   "checklist_no": null
  //   },
  //   {
  //   "quotation_item_id": "41",
  //   "sub_item_no": "2",
  //   "sub_item_name": "Chocolate Cake",
  //   "main_item_name": "Desserts",
  //   "is_checked": "0",
  //   "checklist_no": null
  //   }
  //   ]
  //   },
  //   "Snacks": {
  //   "quantity": 0,
  //   "servers": 0,
  //   "per_plate": 0,
  //   "items": []
  //   },
  //   "Dinner": {
  //   "quantity": 0,
  //   "servers": 0,
  //   "per_plate": 0,
  //   "items": []
  //   }
  //   }];

  const showModal = (type, quota) => {
    setModalType(type);
    setCurrentQuotation(quota);
    if (type === 'edit') {
      form.setFieldsValue({
        total: quota.total,
        event_date: quota.event_date ? dayjs(quota.event_date, 'YYYY-MM-DD') : null,
        quote_status: quota.quote_status,
        advance: quota.advance,
        person_service_charge: quota.person_service_charge,
        transportation_charges: quota.transportation_charges,
        
        
      });
    }
    setIsModalVisible(true);
  };



  const handleViewDetails = (content,quota) => {
    setModalType('view');
    setCurrentQuotation(quota);
    setModalContent(content);
    setIsModalVisible(true);
  };


  const handleOk = () => {
    if (modalType === 'delete') {
      axios.delete(`${process.env.REACT_APP_API_BASE_URL}/quotation.php`, { data: { quotation_id: currentQuotation.quotation_id } }, {
        headers: {
          "Accept": "*/*",
          "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
        }
      })
      .then(() => {
        message.success('Quotation deleted successfully!');
        quotationApi();
      })
      .catch(() => {
        message.error('Error deleting quotation!');
      })
      .finally(() => {
        setIsModalVisible(false);
        setCurrentQuotation(null);
    setModalContent('');
      });
    } else if (modalType === 'edit') {
      form.validateFields().then((values) => {
        axios.put(`${process.env.REACT_APP_API_BASE_URL}/quotation.php`, { ...currentQuotation, ...values,event_date: values.event_date.format('YYYY-MM-DD') }, {
          headers: {
            "Accept": "*/*",
            "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
          }
        })
        .then(() => {
          message.success('Quotation updated successfully!');
          quotationApi();
        })
        .catch(() => {
          message.error('Error updating quotation!');
        })
        .finally(() => {
          setIsModalVisible(false);
          setCurrentQuotation(null);
    setModalContent('');
        });
      }).catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    }
  };

  
  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentQuotation(null);
    setModalContent('');
  };





  const renderModalContent = () => {
    if (!currentQuotation) return null;

    const {
      Meals = {},
      Tiffins = {},
      Snacks = {},
      Dinner = {},
      event_date,
      date_created,
      quote_status,
      advance,
      person_service_charge,
      transportation_charges
    } = currentQuotation;

   


    return (
      <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px', border: '1px solid #ddd',margin:'auto' }}>
        <h2 style={{ textAlign: 'center', margin: '0' }}>Quotation Details</h2>
        <div style={{ marginBottom: '20px', borderBottom: '2px solid #000', paddingBottom: '10px'}}>
          <p><strong>Customer Name:</strong> {currentQuotation.customer_details.customer_name}</p>
          <p><strong>Mobile No:</strong> {currentQuotation.customer_details.customer_mobile}</p>
          <p><strong>Event Date:</strong> {event_date}</p>
          <p><strong>Date Created:</strong> {date_created}</p>
          <p><strong>Quote Status:</strong> {quote_status}</p>
          <p><strong>Advance:</strong> {advance}</p>
          <p><strong>Person Service Charge:</strong> {person_service_charge}</p>
          <p><strong>Transportation Charges:</strong> {transportation_charges}</p>
        </div>

        <div style={{ marginBottom: '20px' }}>
          {modalContent === 'meals' && Meals.quantity > 0 && (
            <div>
              <h4>Meals</h4>
              <p><strong>Quantity:</strong> {Meals.quantity}</p>
              <p><strong>Servers:</strong> {Meals.servers}</p>
              <p><strong>Per Plate:</strong> {Meals.per_plate}</p>
              {Meals.items && Meals.items.length > 0 ? (
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Meals.items.map((meal, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{meal.main_item_name}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{meal.sub_item_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No Meals Data</p>
              )}
            </div>
          )}
          {modalContent === 'tiffins' && Tiffins.quantity > 0 && (
            <div>
              <h4>Tiffins</h4>
              <p><strong>Quantity:</strong> {Tiffins.quantity}</p>
              <p><strong>Servers:</strong> {Tiffins.servers}</p>
              <p><strong>Per Plate:</strong> {Tiffins.per_plate}</p>
              {Tiffins.items && Tiffins.items.length > 0 ? (
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Tiffins.items.map((item, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.main_item_name}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.sub_item_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No Tiffins Data</p>
              )}
            </div>
          )}
          {modalContent === 'snacks' && Snacks.quantity > 0 && (
            <div>
              <h4>Snacks</h4>
              <p><strong>Quantity:</strong> {Snacks.quantity}</p>
              <p><strong>Servers:</strong> {Snacks.servers}</p>
              <p><strong>Per Plate:</strong> {Snacks.per_plate}</p>
              {Snacks.items && Snacks.items.length > 0 ? (
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Snacks.items.map((snack, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{snack.main_item_name}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{snack.sub_item_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No Snacks Data</p>
              )}
            </div>
          )}
          {modalContent === 'dinner' && Dinner.quantity > 0 && (
            <div>
              <h4>Dinner</h4>
              <p><strong>Quantity:</strong> {Dinner.quantity}</p>
              <p><strong>Servers:</strong> {Dinner.servers}</p>
              <p><strong>Per Plate:</strong> {Dinner.per_plate}</p>
              {Dinner.items && Dinner.items.length > 0 ? (
                <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Dinner.items.map((dinner, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dinner.main_item_name}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dinner.sub_item_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No Dinner Data</p>
              )}
            </div>
          )}
          {modalContent === 'all' && (
            <div>
              {Meals.quantity > 0 && (
                <div>
                  <h4>Meals</h4>
                  <p><strong>Quantity:</strong> {Meals.quantity}</p>
                  <p><strong>Servers:</strong> {Meals.servers}</p>
                  <p><strong>Per Plate:</strong> {Meals.per_plate}</p>
                  {Meals.items && Meals.items.length > 0 ? (
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Meals.items.map((meal, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{meal.main_item_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{meal.sub_item_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Meals Data</p>
                  )}
                </div>
              )}
              {Tiffins.quantity > 0 && (
                <div>
                  <h4>Tiffins</h4>
                  <p><strong>Quantity:</strong> {Tiffins.quantity}</p>
                  <p><strong>Servers:</strong> {Tiffins.servers}</p>
                  <p><strong>Per Plate:</strong> {Tiffins.per_plate}</p>
                  {Tiffins.items && Tiffins.items.length > 0 ? (
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Tiffins.items.map((item, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.main_item_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.sub_item_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Tiffins Data</p>
                  )}
                </div>
              )}
              {Snacks.quantity > 0 && (
                <div>
                  <h4>Snacks</h4>
                  <p><strong>Quantity:</strong> {Snacks.quantity}</p>
                  <p><strong>Servers:</strong> {Snacks.servers}</p>
                  <p><strong>Per Plate:</strong> {Snacks.per_plate}</p>
                  {Snacks.items && Snacks.items.length > 0 ? (
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Snacks.items.map((snack, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{snack.main_item_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{snack.sub_item_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Snacks Data</p>
                  )}
                </div>
              )}
              {Dinner.quantity > 0 && (
                <div>
                  <h4>Dinner</h4>
                  <p><strong>Quantity:</strong> {Dinner.quantity}</p>
                  <p><strong>Servers:</strong> {Dinner.servers}</p>
                  <p><strong>Per Plate:</strong> {Dinner.per_plate}</p>
                  {Dinner.items && Dinner.items.length > 0 ? (
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                      <thead>
                        <tr>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Item No</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Main Item Name</th>
                          <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Sub Item Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Dinner.items.map((dinner, index) => (
                          <tr key={index}>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dinner.main_item_name}</td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dinner.sub_item_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No Dinner Data</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };



  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const handleFilterColumnChange = (value) => {
    setFilterColumn(value);
    setSearchTerm(''); // Reset search term when filter column changes
    setCurrentPage(1); // Reset to first page on filter column change
  };

  
  const filterQuotations = (quotations) => {
    return quotations.filter((quota) => {
      if (filterColumn === 'customer_name') {
        return quota.customer_details.customer_name.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (filterColumn === 'customer_mobile') {
        return quota.customer_details.customer_mobile.includes(searchTerm);
      } else {
        return quota[filterColumn].toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
    });
  };

  const filteredQuotations = filterQuotations(quotation.filter(q => q.event_date >= startDate && q.event_date <= endDate));

  const paginatedQuotations = filteredQuotations.slice((currentPage - 1) * pageSize, currentPage * pageSize);




  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>Upcoming Events - {quotation.filter(q => q.event_date >= startDate && q.event_date <= endDate).length}</h3>
      <h3 style={{ textAlign: 'center' }}>{startDate} to {endDate}</h3>
      <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center' }}>
        
        <Select
          defaultValue={filterColumn}
          onChange={handleFilterColumnChange}
          style={{ width: '200px', marginBottom: '20px' }}
        >
          <Option value="customer_name">Customer Name</Option>
          
          <Option value="customer_mobile">Mobile Number</Option>
          <Option value="quotation_id">Quotation ID</Option>
          <Option value="event_date">Event Date</Option>
          <Option value="quote_status">Status</Option>
          

        </Select>
        <Input.Search
          placeholder={`Search by ${filterColumn.replace('_', ' ')}`}
          onChange={handleSearchChange}
          value={searchTerm}
          style={{ width: 200, marginBottom: 20 }}
        />
      </div>
      <Row gutter={[16, 16]}>
        {paginatedQuotations.map((item) => (
          <Col key={item.quotation_id} xs={24} sm={12} md={8} lg={6}>
            <Card
              key={item.quotation_id}
              style={{ backgroundColor: 'var(--blue-1)' }}
              hoverable={true}
              title={`Quotation ID: ${item.quotation_id}`}
              extra={
                <Link to={`/users/details/${item.customer_details.customer_no}`}>
                  <Tooltip title="View User">
                    <Button
                      shape="circle"
                      icon={<UserOutlined />}
                      
                    />
                  </Tooltip>
                </Link>
              }
              actions={[
                <Tooltip title="View" key="view">
                  <EyeOutlined onClick={() => handleViewDetails('all',item)} />
                </Tooltip>,
                <Tooltip title="Edit" key="edit">
                  <EditOutlined onClick={() => showModal('edit', item)} />
                </Tooltip>,
                <Tooltip title="Delete" key="delete">
                  <DeleteOutlined onClick={() => showModal('delete', item)} />
                </Tooltip>
              ]}
            >
              {/* <QuotationCard item={item} /> */}
              <Space>
                {item.food_types.tiffins > 0 && (
                  <Tooltip title="Tiffins">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: 'var(--blue-6)', color: 'white' }}
                      onClick={() => handleViewDetails('tiffins',item)}
                    >
                      T
                    </Button>
                  </Tooltip>
                )}
                {item.food_types.meals > 0 && (
                  <Tooltip title="Meals">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: 'var(--blue-6)', color: 'white' }}
                      onClick={() => handleViewDetails('meals',item)}
                    >
                      M
                    </Button>
                  </Tooltip>
                )}
                {item.food_types.snacks > 0 && (
                  <Tooltip title="Snacks">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: 'var(--blue-6)', color: 'white' }}
                      onClick={() => handleViewDetails('snacks',item)}
                    >
                      S
                    </Button>
                  </Tooltip>
                )}
                {item.food_types.dinner > 0 && (
                  <Tooltip title="Dinner">
                    <Button
                      shape="circle"
                      style={{ backgroundColor: 'var(--blue-6)', color: 'white' }}
                      onClick={() => handleViewDetails( 'dinner',item)}
                    >
                      D
                    </Button>
                  </Tooltip>
                )}
              </Space>
              <br /><br />
              <span><b>Customer Name:</b> {item.customer_details.customer_name}</span><br />
              <span><b>Mobile No:</b> {item.customer_details.customer_mobile}</span><br />
              <span><b>Event Date:</b> {item.event_date}</span><br />
              <span><b>Person Service Charge:</b> {item.person_service_charge}</span><br />
              <span><b>Transportation Charges:</b> {item.transportation_charges}</span><br />
              <span><b>Total:</b> {item.total}</span><br />
            </Card>
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={filteredQuotations.length}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        style={{ marginTop: 20, textAlign: 'center', display: 'flex', justifyContent: 'center' }}
      />
      <Modal
      title={modalType === 'edit' ? 'Edit Quotation' : modalType === 'view' ? 'View Quotation' : 'Delete Quotation'}
        
        open={isModalVisible}
        width={'100%'}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={modalType === 'view' ? null : [
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {modalType === 'delete' ? 'Delete' : 'OK'}
          </Button>
        ]}
      >
        {modalType === 'view' ? renderModalContent() : modalType === 'edit' ? (
          <Form form={form} layout="vertical">
            
            <Form.Item label="Event Date" name="event_date" rules={[{ required: true, message: 'Please select the event date!' }]}>
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item label="Quote Status" name="quote_status" rules={[{ required: true, message: 'Please select the quote status!' }]}>
              <Select>
                <Option value="Pending">Pending</Option>
                <Option value="Completed">Completed</Option>
                
              </Select>
            </Form.Item>
            <Form.Item label="Advance" name="advance" rules={[{ required: true, message: 'Please input the advance!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Person Service Charge" name="person_service_charge" rules={[{ required: true, message: 'Please input the person service charge!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Transportation Charges" name="transportation_charges" rules={[{ required: true, message: 'Please input the transportation charges!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Total" name="total" rules={[{ required: true, message: 'Please input the total amount!' }]}>
              <Input />
            </Form.Item>
           
            
          
        </Form>
        ): (
          <p>Are you sure you want to delete this customer?</p>
        )}
      </Modal>
    </div>
  );
};

export default MasterData;
import React, { useEffect, useState,createContext,useContext } from 'react'
import { fetchData } from './FetchData';

import { LoginContext } from './LoginProvider';
export const MyContext = createContext();

export const MyProvider = ({children}) => {

const { loginToken } = useContext(LoginContext);    
const [checklist,setChecklist]=useState([]);
// const [checklist_item,setChecklist_item]=useState([]);
const [customers,setCustomers]=useState([]);
// const [food_type,setFood_type]=useState([]);
const [main_item,setMain_item]=useState([]);
const [quotation,setQuotation]=useState([]);
// const [quotation_item,setQuotation_item]=useState([]);
// const [quotation_type,setQuotation_type]=useState([]);
const [recipe,setRecipe]=useState([]);    
const [service_booking,setService_booking]=useState([]);
// const [service_types,setService_types]=useState([]);
const [sub_item,setSub_item]=useState([]);
const [shopping_list,setShopping_list]=useState([]);
const [main_shopping,setMain_shopping]=useState([]);
const [sub_shopping,setSub_shopping]=useState([]);


useEffect(()=>{
   

    if (loginToken) {
//activities related to checklist tab    
if(checklist.length==0){checklistApi()}
// if(checklist_item.length==0){checklist_itemApi()} 

//activities related to customers tab
if(customers.length==0){customersApi()}



//activities related to products tab
if(main_item.length==0){main_itemApi()}
if(sub_item.length==0){sub_itemApi()}

//activities related to quotation tab
if(quotation.length==0){quotationApi()}

//activities related to recipe tab
if(recipe.length==0){recipeApi()}

//activities related to services tab
if(service_booking.length==0){service_bookingApi()}
// if(service_types.length==0){service_typesApi()}


//activities related to shopping tab
if(shopping_list.length==0){shopping_listApi()}
if(main_shopping.length==0){main_shoppingApi()}
if(sub_shopping.length==0){sub_shoppingApi()}


// if(food_type.length==0){food_typeApi()}
// if(quotation_item.length==0){quotation_itemApi()}
// if(quotation_type.length==0){quotation_typeApi()}


    }



},[loginToken]);


async function checklistApi(){
    const checklistApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/checklist.php`);
    setChecklist(checklistApiData);
};

// async function checklist_itemApi(){
//     const checklist_itemApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/checklist_detailed_view.php`);
//     setChecklist_item(checklist_itemApiData);
// };

async function customersApi(){
    const customersApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/customers.php`);
    setCustomers(customersApiData);
};

// async function food_typeApi(){
//     const food_typeApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/food_type.php`);
//     setFood_type(food_typeApiData);
// };

async function main_itemApi(){
    const main_itemApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/main_item.php`);
    setMain_item(main_itemApiData);
};

async function quotationApi(){
    const quotationApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/quotation.php`);
    setQuotation(quotationApiData);
};

// async function quotation_itemApi(){
//     const quotation_itemApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/quotation_item.php`);
//     setQuotation_item(quotation_itemApiData);
// };

// async function quotation_typeApi(){
//     const quotation_typeApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/quotation_type.php`);
//     setQuotation_type(quotation_typeApiData);
// }; 

async function recipeApi(){
    const recipeApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/recipe.php`);
    setRecipe(recipeApiData);
};

async function service_bookingApi(){
    const service_bookingApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/service_booking.php`);
    setService_booking(service_bookingApiData);
};

// async function service_typesApi(){
//     const service_typesApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/service_types.php`);
//     setService_types(service_typesApiData);
// };

async function sub_itemApi(){
    const sub_itemApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/sub_item.php`);
    setSub_item(sub_itemApiData);
};

async function shopping_listApi(){
    const shopping_listApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/shoppinglist.php`);
    setShopping_list(shopping_listApiData);
};

async function main_shoppingApi(){
    const main_shoppingApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/main_shopping.php`);
    setMain_shopping(main_shoppingApiData);
};

async function sub_shoppingApi(){
    const sub_shoppingApiData=await fetchData(`${process.env.REACT_APP_API_BASE_URL}/sub_shopping.php`);
    setSub_shopping(sub_shoppingApiData);
};



  return (
    <MyContext.Provider value={{checklist,customers,main_item,quotation,recipe,service_booking,sub_item,shopping_list,main_shopping,sub_shopping,
    main_itemApi,sub_itemApi,recipeApi,service_bookingApi,shopping_listApi,customersApi,quotationApi,sub_shoppingApi}}>
        {children}
        </MyContext.Provider>
  );
}




import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {MyContext} from '../MyProvider'; // Import your context
import UserContainer from './UserDetailed/UserContainer';


const UserDetails = () => {
  const { customer_id } = useParams();
  
  const { customers } = useContext(MyContext);
  
  const customer = customers.find(c => c.customer_id === parseInt(customer_id));

  
  return (
    <>
    <hr />
    <UserContainer customer={customer}/>
    <hr />
    </>
  );
};

export default UserDetails;

import React from 'react';
import {
    DashboardOutlined,
    FileTextOutlined,
    TableOutlined,
    FormOutlined,
    EditOutlined,
    UnorderedListOutlined,
    CalendarOutlined,
    GiftOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    UserAddOutlined,
    ProfileOutlined,
    BookOutlined,
    CheckSquareOutlined,
    OrderedListOutlined,
    DollarOutlined,
    PictureOutlined,
    PlusOutlined,
    AppstoreOutlined
} from '@ant-design/icons';

const items = [
  {
    key: 'dashboard',
    icon: <DashboardOutlined />,
    label: 'Dashboard',
    path: '/',
    children: [
      {
        key: 'masterData',
        icon: <DashboardOutlined />,
        label: 'Master Data',
        path: '/'
      }
    ]
  },
  {
    key: 'services',
    icon: <FileTextOutlined />,
    label: 'Services',
    path: '/services/submissions',
    children: [
      {
        key: 'submissions',
        icon: <TableOutlined />,
        label: 'Submissions',
        path: '/services/submissions'
      }
    ]
  },
  {
    key: 'quotations',
    icon: <FormOutlined />,
    label: 'Quotations',
    path: '/quotations/create',
    children: [
      {
        key: 'createQuotation',
        icon: <EditOutlined />,
        label: 'Create Quote',
        path: '/quotations/create'
      },
      {
        key: 'quotationList',
        icon: <UnorderedListOutlined />,
        label: 'Quoted List',
        path: '/quotations/list'
      }
    ]
  },
  {
    key: 'users',
    icon: <UserOutlined />,
    label: 'Users',
    path: '/users/create',
    children: [
      {
        key: 'createUser',
        icon: <UserAddOutlined />,
        label: 'Create User',
        path: '/users/create'
      },
      {
        key: 'userList',
        icon: <UnorderedListOutlined />,
        label: 'User List',
        path: '/users/list'
      },
      {
        key: 'birthdays',
        icon: <GiftOutlined />,
        label: 'Birthdays',
        path: '/users/birthdays'
      }
    ]
  },
  {
    key: 'recipes',
    icon: <BookOutlined />,
    label: 'Recipes',
    path: '/recipes/create',
    children: [
      {
        key: 'createRecipe',
        icon: <EditOutlined />,
        label: 'Create Recipe',
        path: '/recipes/create'
      },
      {
        key: 'recipeList',
        icon: <UnorderedListOutlined />,
        label: 'Recipe List',
        path: '/recipes/list'
      }
    ]
  },
  // {
  //   key: 'events',
  //   icon: <CalendarOutlined />,
  //   label: 'Events',
  //   path: '/events/list',
  //   children: [
  //     {
  //       key: 'eventList',
  //       icon: <UnorderedListOutlined />,
  //       label: 'Event List',
  //       path: '/events/list'
  //     }
  //   ]
  // },
  {
    key: 'checklist',
    icon: <CheckSquareOutlined />,
    label: 'Checklist',
    path: '/checklist/items',
    children: [
      {
        key: 'checklistItems',
        icon: <OrderedListOutlined />,
        label: 'Check Items',
        path: '/checklist/items'
      },
      {
        key: 'checklistList',
        icon: <UnorderedListOutlined />,
        label: 'Check List',
        path: '/checklist/list'
      }
    ]
  },
  {
    key: 'revenue',
    icon: <DollarOutlined />,
    label: 'Revenue',
    path: '/revenue/records',
    children: [
      {
        key: 'revenueRecords',
        icon: <TableOutlined />,
        label: 'Records',
        path: '/revenue/records'
      }
    ]
  },
  {
    key: 'gallery',
    icon: <PictureOutlined />,
    label: 'Gallery',
    path: '/gallery/add',
    children: [
      {
        key: 'addImage',
        icon: <PlusOutlined />,
        label: 'Add Images',
        path: '/gallery/add'
      },
      {
        key: 'galleryList',
        icon: <UnorderedListOutlined />,
        label: 'View Images',
        path: '/gallery/list'
      }
    ]
  },
  {
    key: 'shopping',
    icon: <ShoppingCartOutlined />,
    label: 'Shopping',
    path: '/shopping/create',
    children: [
      {
        key: 'createShopping',
        icon: <EditOutlined />,
        label: 'Create List',
        path: '/shopping/create'
      },
      {
        key: 'shoppingList',
        icon: <UnorderedListOutlined />,
        label: 'Prepared Lists',
        path: '/shopping/list'
      },
      {
        key: 'shoppingListItems',
        icon: <UnorderedListOutlined />,
        label: 'List Items',
        path: '/shopping/list-items'
      },
      {
        key:'AddShoppingItems',
        icon: <PlusOutlined />,
        label: 'Add Items',
        path: '/shopping/add-items'
      }
    ]
  },
  {
    key: 'product',
    icon: <AppstoreOutlined />,
    label: 'Products',
    path: '/products/create',
    children: [
      {
        key: 'createProduct',
        icon: <EditOutlined />,
        label: 'Add Product',
        path: '/products/create'
      },
      {
        key: 'createMainProduct',
        icon: <EditOutlined />,
        label: 'Add Main Product',
        path: '/products/create-main'
      },
      {
        key: 'productList',
        icon: <UnorderedListOutlined />,
        label: 'View Products',
        path: '/products/list'
      }
    ]
  }
];

export default items;

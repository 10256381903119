import React, { useState, useContext } from 'react';
import { Table, Button, Modal } from 'antd';
import { MyContext } from '../MyProvider';

const CheckList = () => {
  const { quotation, checklist } = useContext(MyContext);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [currentChecklistId, setCurrentChecklistId] = useState(null);

  const handleViewDetails = (quotationId, checklistId) => {
    // console.log('View details for quotation ID:', quotationId);
    if (!quotation || quotation.length === 0) {
      console.warn('No quotations available');
      return;
    }

    const selected = quotation.find(q => q.quotation_id == quotationId);
    // console.log('Selected Quotation:', selected);
    setSelectedQuotation(selected);
    setCurrentChecklistId(checklistId);
  };

  const columns = [
    {
      title: 'Checklist No',
      dataIndex: 'checklist_id',
      key: 'checklist_id',
    },
    {
      title: 'Date Created',
      dataIndex: 'date_created',
      key: 'date_created',
    },
    {
      title: 'Quotation No',
      dataIndex: 'quotation_no',
      key: 'quotation_no',
    },
    {
      title: 'Loaded Person',
      dataIndex: 'loaded_person',
      key: 'loaded_person',
    },
    {
      title: 'Servers Assigned',
      dataIndex: 'servers_assign',
      key: 'servers_assign',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleViewDetails(record.quotation_no, record.checklist_id)}>View Details</Button>
      ),
    },
  ];

  const renderQuotationDetails = () => {
    if (!selectedQuotation || !currentChecklistId) return null;

    const {
      customer_details,
      total,
      event_date,
      date_created,
      quotation_id,
      quote_status,
      person_service_charge,
      transportation_charges,
      Tiffins,
      Meals,
      Snacks,
      Dinner,
    } = selectedQuotation;

    const filterItemsByChecklistNo = (items) => {
      return items.filter(item => item.checklist_no == currentChecklistId);
    };

    const createTableData = (foodType) => {
      return filterItemsByChecklistNo(foodType.items).map(item => ({
        key: item.quotation_item_id,
        main_item_name: item.main_item_name,
        sub_item_name: item.sub_item_name,
      }));
    };

    const columnsForItems = [
      {
        title: 'Main Item Name',
        dataIndex: 'main_item_name',
        key: 'main_item_name',
      },
      {
        title: 'Sub Item Name',
        dataIndex: 'sub_item_name',
        key: 'sub_item_name',
      },
    ];

    const tiffinsData = createTableData(Tiffins);
    const mealsData = createTableData(Meals);
    const snacksData = createTableData(Snacks);
    const dinnerData = createTableData(Dinner);

    return (
      <div>
        <p><strong>Quotation ID:</strong> {quotation_id}</p>
        <p><strong>Date Created:</strong> {date_created}</p>
        <p><strong>Event Date:</strong> {event_date}</p>
        <p><strong>Customer Name:</strong> {customer_details.customer_name}</p>
        <p><strong>Customer Mobile:</strong> {customer_details.customer_mobile}</p>
        <p><strong>Total:</strong> {total}</p>
        <p><strong>Quote Status:</strong> {quote_status}</p>
        <p><strong>Person Service Charge:</strong> {person_service_charge}</p>
        <p><strong>Transportation Charges:</strong> {transportation_charges}</p>

        {tiffinsData.length > 0 && (
          <div>
            <h3>Tiffins</h3>
            <Table
              columns={columnsForItems}
              dataSource={tiffinsData}
              pagination={false}
            />
          </div>
        )}

        {mealsData.length > 0 && (
          <div>
            <h3>Meals</h3>
            <Table
              columns={columnsForItems}
              dataSource={mealsData}
              pagination={false}
            />
          </div>
        )}

        {snacksData.length > 0 && (
          <div>
            <h3>Snacks</h3>
            <Table
              columns={columnsForItems}
              dataSource={snacksData}
              pagination={false}
            />
          </div>
        )}

        {dinnerData.length > 0 && (
          <div>
            <h3>Dinner</h3>
            <Table
              columns={columnsForItems}
              dataSource={dinnerData}
              pagination={false}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={checklist}
        rowKey="checklist_id"
      />
      <Modal
        title="Quotation Details"
        open={!!selectedQuotation}
        onCancel={() => setSelectedQuotation(null)}
        footer={null}
      >
        {renderQuotationDetails()}
      </Modal>
    </div>
  );
};

export default CheckList;

import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const AddImage = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  const onFinish = (values) => {
    if (fileList.length === 0) {
      message.error('Please upload an image!');
      return;
    }

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('file', fileList[0].originFileObj);

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload.php`, formData, {
      headers: {
        
        "Accept": "*/*",
        "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
      },
    })
    .then((response) => {
      message.success(response.data.message);
      form.resetFields();
      setFileList([]);
    })
    .catch((error) => {
      message.error('Failed to upload image');
    });
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ margin: '0 auto', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '8px', backgroundColor: '#fff' }}
    >
      <Form.Item
        name="name"
        label="Image Name"
        rules={[{ required: true, message: 'Please input the image name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="file"
        label="Upload Image"
        rules={[{ required: true, message: 'Please upload an image!' }]}
      >
        <Upload
          listType="picture"
          beforeUpload={() => false} // Prevent auto upload
          onChange={handleUploadChange}
          fileList={fileList}
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Upload
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddImage;

import React, { useContext, useState } from 'react';
import { Form, Input, Button, DatePicker, message, Descriptions, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { MyContext } from '../../MyProvider'; // Import your context

const { TextArea } = Input;

const UserContainer = ({  customer }) => {
  const [form] = Form.useForm();
  const { customersApi} = useContext(MyContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  
  const showModal = (customer) => {
    form.setFieldsValue({...customer, date_of_birth: customer.date_of_birth ? dayjs(customer.date_of_birth) : null});
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    axios.put(`${process.env.REACT_APP_API_BASE_URL}/customers.php`, {
      ...values,
      customer_id: customer.customer_id,
      mobile_id: customer.mobile_id,
      date_of_birth: values.date_of_birth.format('YYYY-MM-DD'),
    }, {
      headers: {
        "Accept": "*/*",
        "User-Agent": "bvilas (https://www.admin.bramhanavilas.com)"
      }
    })
      .then(response => {
        message.success('Customer details updated successfully.');
        customersApi(); // Refresh data
        setIsModalVisible(false); // Exit modal
      })
      .catch(error => {
        message.error('Failed to update customer details.');
      });
  };

  return (
    <div style={{ margin: '0 auto', background: '#fff' }}>
      <Descriptions
        title="User Details"
        extra={<EditOutlined style={{ cursor: 'pointer' }} onClick={() => showModal(customer)} />}
      >
        <Descriptions.Item label="Customer Name">{customer.customer_name}</Descriptions.Item>
        <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
        <Descriptions.Item label="Date of Birth">{customer.date_of_birth}</Descriptions.Item>
        <Descriptions.Item label="Mobile Number">{customer.mobile_id}</Descriptions.Item>
        <Descriptions.Item label="Address">{customer.address}</Descriptions.Item>
      </Descriptions>

      <Modal
        title="Edit Customer Details"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} key={customer.customer_id} layout="vertical" onFinish={onFinish}>
          <Form.Item name="customer_name" label="Customer Name" rules={[{ required: true, message: 'Please input the customer name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'The input is not valid E-mail!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="date_of_birth" label="Date of Birth">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={handleCancel}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserContainer;

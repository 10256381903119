import React from 'react'
import {  Route, Routes } from 'react-router-dom';


import MasterData from '../components/MasterData';

import Submissions from '../components/Submissions';

import CreateQuotation from '../components/CreateQuotation';
import QuotationList from '../components/QuotationList';

import CreateUser from '../components/CreateUser';
import UserList from '../components/UserList';
import BirthdayList from '../components/BirthdayList';

import CreateRecipe from '../components/CreateRecipe';
import RecipeList from '../components/RecipeList';

// import EventList from '../components/EventList';

import ChecklistItems from '../components/ChecklistItems';

import RevenueRecords from '../components/RevenueRecords';

import AddImage from '../components/AddImage';
import GalleryList from '../components/GalleryList';

import CreateShopping from '../components/CreateShopping';
import ShoppingList from '../components/ShoppingList';

import CreateProduct from '../components/CreateProduct';
import CreateMainProduct from '../components/CreateMainProduct';
import ProductList from '../components/ProductList';
import UserDetails from '../components/UserDetails';
import CheckList from '../components/CheckList';
import ShoppingListItems from '../components/ShoppingListItems';
import AddShoppingItems from '../components/AddShoppingItems';


const Allroutes = () => {
  return (
    <Routes>
              
              <Route path="/" element={<MasterData />} />

              <Route path="/services/submissions" element={<Submissions />} />

              <Route path="/quotations/create" element={<CreateQuotation />} />
              <Route path="/quotations/list" element={<QuotationList />} />

              <Route path="/users/create" element={<CreateUser />} />
              <Route path="/users/list" element={<UserList />} />
             
              <Route path="/users/birthdays" element={<BirthdayList />} />
              <Route path="/users/details/:customer_id" element={<UserDetails />} />

              <Route path="/recipes/create" element={<CreateRecipe />} />
              <Route path="/recipes/list" element={<RecipeList />} />

              {/* <Route path="/events/list" element={<EventList />} /> */}

              <Route path="/checklist/items" element={<ChecklistItems />} />
              <Route path="/checklist/list" element={<CheckList />} />

              <Route path="/revenue/records" element={<RevenueRecords />} />

              <Route path="/gallery/add" element={<AddImage />} />
              <Route path="/gallery/list" element={<GalleryList />} />
              
              <Route path="/shopping/create" element={<CreateShopping />} />
              <Route path="/shopping/list" element={<ShoppingList />} />
              <Route path="/shopping/list-items" element={<ShoppingListItems />} />
              <Route path="/shopping/add-items" element={<AddShoppingItems />} />

              <Route path="/products/create" element={<CreateProduct />} />
              <Route path="/products/create-main" element={<CreateMainProduct />} />
              <Route path="/products/list" element={<ProductList />} />
            </Routes>

  )
}

export default Allroutes
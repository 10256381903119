import React, { useContext } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { LoginContext } from './LoginProvider';
import logo from "./assets/logo.svg"

const Login = () => {
  const { setLoginToken } = useContext(LoginContext);

  const onFinish = (values) => {
   
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/login.php`, values)
      .then((res) => {
        const data = res.data;
        if (data.message === "Login successful") {
          message.success("Login successful");
          setLoginToken(data.apiToken);
        } else {
          message.error("Invalid credentials");
        }
      })
      .catch(() => message.error("Server Error"));
  };

  return (
    <div style={{ background: '#F5f5f4', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{
        width: '80%',
        maxWidth: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2%',
        borderRadius: '10px',
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        backgroundColor: '#fff'
      }}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ width: '100%' }}
        >
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img src={logo} alt="Login" style={{ width: '100px', height: '100px' }} />
          </div>
          <Form.Item
            name="userName"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
